@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Hebrew:wght@100;200;300;400;500;600;700&family=Roboto+Flex:wght@100;200;300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap&family=Roboto+Mono:wght@100;200;300;400;500;600;700 ');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html::-webkit-scrollbar {
  display: none;
}

html,
body {
  max-width: 100vw;
  background: white;
  color: white;
  scrollbar-width: none;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
}

